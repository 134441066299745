.card-columns {
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 5;
  }
}

.grid-Container {
  overflow-y: scroll;
  height: 80vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.grid-Container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.grid-Container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Col {
  /* border:1px solid white; */
  margin-bottom: 15px;
}

.job {
  margin: 25px;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  height: 260px !important;
  box-shadow: -7px 8px 16px 3px #000;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  /* VERZÖGERUNG */
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.job:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

#containerDiv {
  padding-top: 20px;
  width: 100%;
  height: 100vh;

  position: relative;
  background-image: url('../assets//back_2.jpg');
  background-attachment: fixed;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.title {
  text-align: center;
  color: #080;
}
.center-card {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
