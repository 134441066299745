.container {
  margin: 30px;
  align-items: center;
  text-align: center;
}
.center-stepper {
  display: flex;
  justify-content: center;
}
.stepper {
  width: 25%;
}
